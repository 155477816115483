import axios from 'axios'

require('./bootstrap');

window.toggleButtonText = function (button, subject) {
    let text = ($(button).text() === `Pokaż listę ${subject}`) ? `Zwiń listę ${subject}` : `Pokaż listę ${subject}`;
    $(button).text(text);
}

window.toggleContent = function (menuElement, contentWrapper) {
    const contentWrappers = $('.content-wrapper');
    const currentWrapper = $(`#${contentWrapper}`);

    contentWrappers.fadeOut('fast');
    currentWrapper.fadeIn('fast');
    $('#tabs-menu .item').removeClass('active');
    $(menuElement).addClass('active');
}
